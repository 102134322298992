import { ReactElement } from 'react'
import { pxToRemUnit } from 'utils/styled'
import { SIconWrapper } from './IconWrapper.styled'

interface Props {
  children?: ReactElement
  backgroundColor?: string
  iconColor?: string
  iconSize?: number
  size?: number
  className?: string
}

export const IconWrapper = ({
  children,
  className,
  backgroundColor = 'var(--c-orange200)',
  iconColor = 'white',
  iconSize,
  size = 32,
}: Props) => {
  return (
    <SIconWrapper
      className={className}
      css={[
        {
          backgroundColor,
          color: iconColor,
          width: size,
          height: size,
        },
        iconSize != null
          ? {
              svg: {
                width: pxToRemUnit(iconSize),
                height: pxToRemUnit(iconSize),
              },
            }
          : undefined,
      ]}
    >
      {children}
    </SIconWrapper>
  )
}
