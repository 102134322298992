import { ClassNames } from '@emotion/react'
import { Button } from 'components/button/Button'
import { Icon } from 'components/icon'
import {
  SMobileNavigationModal,
  SMobileNavigationModalContent,
  SModalActionButtons,
  SModalPath,
  SModalPathNameContainer,
} from './Navigation.styled'
import { PathProps } from 'components/navigation/Path'
import { Text } from 'components/Typography'
import { AnimatePresence } from 'framer-motion'
import Link from 'next/link'
import Router from 'next/router'
import { cloneElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import ReactModal from 'react-modal'
import { sleep } from 'utils/general'

export const MobileNavigationModal = (props: {
  isOpen: boolean
  isClosing?: boolean
  onRequestClose: () => void
  customContent?: ReactNode
  paths?: PathProps[]
}) => {
  const [active, setActive] = useState<number | null>(0)

  return (
    <ClassNames>
      {({ css }) => (
        <ReactModal
          isOpen={props.isOpen}
          onRequestClose={props.onRequestClose}
          shouldCloseOnOverlayClick={true}
          ariaHideApp={false}
          overlayClassName={css`
            z-index: 2147483001;
            position: fixed;
            top: var(--h-landing-page-header);
            left: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
            height: 100%;
            display: flex;
            flex-direction: column;
          `}
          className={css`
            transform: none;
            border-radius: 0;
            outline: none;
            height: 100%;
          `}
        >
          <AnimatePresence exitBeforeEnter>
            <SMobileNavigationModal
              key="navigation_modal"
              initial={{ opacity: 0 }}
              animate={props.isClosing ? { opacity: 0 } : { opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: 'linear', duration: 0.1 }}
            >
              {props.paths?.length && (
                <>
                  <SMobileNavigationModalContent>
                    {props.paths.map((p, i) => {
                      if (p.isAction) return null
                      const content = (
                        <SModalPath key={`parent_path_${p.name}`}>
                          <SModalPathNameContainer
                            isOpen={i === active}
                            onClick={(e) => {
                              if (i !== active) {
                                setActive(i)
                              } else if (p.children && !p.url) {
                                setActive(null)
                                e.stopPropagation()
                                e.preventDefault()
                                return false
                              }
                              return true
                            }}
                          >
                            <Text variant="bodyBold" color="black">
                              {p.name}
                            </Text>
                            {!!p.children && <Icon name="ChevronDown" size="1rem" />}
                          </SModalPathNameContainer>
                          {!!p.children && (
                            <AnimatePresence exitBeforeEnter>
                              {i === active &&
                                cloneElement(p.children, {
                                  key: `child_paths_container_${p.name}`,
                                  initial: { height: 0 },
                                  animate: { height: 'unset' },
                                  exit: { height: 0 },
                                  transition: { ease: 'easeOut', duration: 0.2 },
                                })}
                            </AnimatePresence>
                          )}
                        </SModalPath>
                      )
                      if (p.url) {
                        return (
                          <Link href={p.url} passHref key={`direct_path_${p.name}`} legacyBehavior>
                            {content}
                          </Link>
                        )
                      } else return content
                    })}
                  </SMobileNavigationModalContent>
                  <SModalActionButtons>
                    {props.paths.map((p, i) => {
                      if (!p.isAction) return null
                      return cloneElement(
                        <Button
                          type="button"
                          href={p.url as string}
                          key={p.url}
                          size="large"
                          variant={i % 2 ? 'primary' : 'secondary'}
                        />,
                        { type: 'button' },
                        [p.name]
                      )
                    })}
                  </SModalActionButtons>
                </>
              )}

              {!!props.customContent && (
                <SMobileNavigationModalContent>{props.customContent}</SMobileNavigationModalContent>
              )}
            </SMobileNavigationModal>
          </AnimatePresence>
        </ReactModal>
      )}
    </ClassNames>
  )
}

export const useMobileNavigationModal = (config: {
  paths?: PathProps[]
  customContent?: ReactNode
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isClosing, setIsClosing] = useState(false)
  const closeCallback = useRef<(() => void) | null>(null)

  const openModal: () => Promise<void> = useCallback(
    () =>
      new Promise((resolve) => {
        setIsClosing(false)
        setIsOpen(true)
        closeCallback.current = resolve
      }),
    []
  )

  const closeModal = useCallback(async () => {
    if (closeCallback.current) {
      closeCallback.current()
    }
    setIsClosing(true)
    await sleep(100)
    setIsOpen(false)
  }, [])

  useEffect(() => {
    Router.events.on('routeChangeStart', closeModal)
    window.addEventListener('beforeunload', closeModal)

    return () => {
      Router.events.off('routeChangeStart', closeModal)
      window.removeEventListener('beforeunload', closeModal)
    }
  }, [closeModal])

  const modalLayout = isOpen ? (
    <MobileNavigationModal
      isOpen={isOpen}
      isClosing={isClosing}
      onRequestClose={closeModal}
      customContent={config.customContent}
      paths={config.paths}
    />
  ) : null

  return [modalLayout, isOpen, openModal, closeModal] as const
}
