import Link, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import { cloneElement, isValidElement, ReactElement, ReactNode } from 'react'
import { logWarn } from 'utils/general'

export interface IActiveLinkCallback {
  (
    props: LinkProps,
    router: {
      route: string
      query: ParsedUrlQuery
      asPath: string
    }
  ): boolean
}

export function ActiveLink({
  children,
  isActive = isExactLink,
  disabled,
  ...props
}: LinkProps & {
  children?: ReactNode
  isActive?: IActiveLinkCallback
  onClick?: () => void
  disabled?: boolean
}) {
  const router = useRouter()
  const active =
    typeof isActive === 'function'
      ? isActive(props, {
          route: router.route,
          asPath: router.asPath,
          query: router.query,
        })
      : false
  if (isValidElement(children)) {
    return (
      <Link {...props} legacyBehavior>
        {cloneElement(children as ReactElement as ReactElement, { active, disabled })}
      </Link>
    )
  }
  return (
    <Link {...props} legacyBehavior>
      {children}
    </Link>
  )
}

export const isExactLink: IActiveLinkCallback = (props, router) => {
  if (router.route !== props.href) {
    return false
  }

  return !(props.as && router.asPath !== props.as)
}

export const isSubresouceLink: IActiveLinkCallback = (props, router) => {
  if (typeof props.href === 'string') {
    return router.route.startsWith(props.href) || router.asPath.startsWith(props.href)
  }

  logWarn('UrlObject href is not supported in isSubresourceLink')
  return false
}
