import styled from '@emotion/styled'

export const SIconWrapper = styled.span`
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;

  svg {
    width: 55%;
    height: 55%;
  }
`
